import { memo, useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import LoadingA from "components/Loadings/SecondLoad";

const BarChartEnergyTrends = ({ title, datas }) => {
  const [display, setDisplay] = useState(false);
  const [dataBars, setDataBars] = useState({
    discharge: [],
    consumption: [],
  });
  const options = {
    series: [
      {
        name: "Discharged",
        data: [...dataBars?.discharge],
      },
      {
        name: "Load Consumption",
        data: [...dataBars?.consumption],
      },
    ],
    chart: {
      type: "bar",
      height: "400",
      with: "auto",
      animations: {
        enabled: false,
      },
    },
    colors: ["#ff9210", "#e53998"],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    },
  };

  const handleChartRender = () => {
    if (datas?.discharge !== undefined && datas?.consumption !== undefined) {
      setDataBars({
        discharge: datas?.discharge,
        consumption: datas?.consumption,
      });

      let barChart = new ApexCharts(
        document.querySelector("#barMonths"),
        options
      );

      barChart.render();

      setTimeout(() => {
        setDisplay(true);
      }, 2000);
    }
  };

  useEffect(() => {
    handleChartRender();
  }, [datas]);

  if (!display) {
    return (
      <>
        <LoadingA />
      </>
    );
  }

  return (
    <div className="mb-3 px-3">
      <div className="barchart-apex">
        <h1>{title ?? ""}</h1>

        <div id="barMonths"></div>
      </div>
    </div>
  );
};

export default memo(BarChartEnergyTrends);
