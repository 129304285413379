import { memo, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const GoogleMaps = () => {
  const defaultProps = {
    center: {
      lat: 13.7269882,
      lng: 100.5113313,
    },
    zoom: 17,
  };

  useEffect(() => {}, []);

  return (
    // Important! Always set the container height explicitly
    <>
      <div className="mb-3" style={{ height: "40vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLEMAP_KEY}` }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent
            lat={13.7269882}
            lng={100.5113313}
            text="This Location"
          />
        </GoogleMapReact>
      </div>
    </>
  );
};

export default memo(GoogleMaps);
