import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./styles/index.scss";

import Layout from "components/Layout";
import Login from "features/auths/Login";

// import main layouts
import MainLayouts from "./layouts/MainLayouts";
import RequireAuth from "./features/auths/RequireAuth";

function App() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Layout />}>
          {/** Public Routes */}
          <Route index element={<Login />} />
        </Route>

        {/** Private Routes */}

        <Route element={<RequireAuth />}>
          <Route path="*" element={<MainLayouts />} />
        </Route>
      </Routes>
    </>
  );
}

export const checkAuthentication = () => {
  // alert("Hello Check Authen");
  const authens = localStorage.getItem("Autorization");
  if (!authens) {
    return true;
  } else {
    return false;
  }
};

export default App;
