import { memo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useMount } from "hooks";

import { getPowerMeterMutiResult } from "Services";

// Component Loading
import LoadingA from "components/Loadings/SecondLoad";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function MultilineCharts({ title, id }) {
  const [series, setSeries] = useState([]);
  const [display, setDisplay] = useState(false);
  const [categories, setCategories] = useState([]);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    pointRadius: 0.1,
    fill: false,
    showLine: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: "#F5F5F5",
        },
      },
    },
  };

  const labels = [...categories];

  const data = {
    labels,
    datasets: [...series],
  };

  const fetchDataDefault = async () => {
    setDisplay(false);
    const filter = {};
    const { data: results } = await getPowerMeterMutiResult(filter, id);
    setSeries(results?.series);
    setCategories(results?.categories);
    setDisplay(true);
  };

  useMount(() => void fetchDataDefault());

  if (!display) {
    return (
      <>
        <LoadingA />
      </>
    );
  } else {
    return (
      <div className="chart-title">
        <h1 className="my-3">{title ?? ""}</h1>
        <Line options={options} data={data} style={{ height: 420 }} />
      </div>
    );
  }
}

export default memo(MultilineCharts);
