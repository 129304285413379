import { memo } from "react";

const Anouncements = ({ initialData }) => {
  return (
    <div className="anouncement-card">
      <h1 className="mb-1 text-enserv-topic ">Anouncement</h1>
      <p>
        <h1>{initialData?.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: initialData?.description }} />
      </p>
    </div>
  );
};

export default memo(Anouncements);
