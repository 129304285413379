import { memo, useState, useEffect } from "react";
import { Image, Col, Row } from "react-bootstrap";
import DayJS from "react-dayjs";
import moment from "moment";
// import icon weathers
import Clear from "assets/images/icons/clear-sky.png";
import Clock from "assets/images/icons/clock.png";

const Weathers = () => {
  const [currentDate, setCurrentDate] = useState(
    moment().format("DD/MM/YYYY HH:mm:ss")
  );

  useEffect(() => {
    setInterval(() => {
      setCurrentDate(moment().format("DD/MM/YYYY HH:mm:ss"));
    }, 1000);
  }, []);
  return (
    <div className="weathers">
      <div className="card-weathers">
        <h1>DAILY WEATHER</h1>
        <hr />
        <Image src={Clear} width={60} />
        <p className="topic-weathers"> CLEAR SKY </p>
        <label>
          We are providing highly recognisable weather products that make
          working with the weather data a way easier. We work with millions of
          developers around.
        </label>
        <hr></hr>
        <Row>
          <Col xl={2} sm={2}>
            <Image src={Clock} width={42} />
          </Col>
          <Col xl={10} sm={10}>
            <label className="text-timer">{`${currentDate}`}</label>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default memo(Weathers);
