import { memo, useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useMount } from "hooks";

// Resful Services
import { getPowerMeterTodayById } from "Services";

// Component Loading
import LoadingA from "components/Loadings/SecondLoad";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ id, title }) => {
  const [display, setDisplay] = useState(false);
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: false,
      },
    },
    pointRadius: 0.1,
    fill: false,
    showLine: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: "#F5F5F5",
        },
      },
    },
  };

  const labels = [...categories];

  const data = {
    labels,
    datasets: [...series],
  };

  const fetchDataDefault = async () => {
    setDisplay(false);
    const filter = {};
    const { data: results } = await getPowerMeterTodayById(filter, id);
    setCategories(results?.categories);
    console.log(results?.series);
    setSeries(results?.series);
    setDisplay(true);
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {}, [categories]);

  if (!display) {
    return (
      <>
        <LoadingA />
      </>
    );
  } else {
    return (
      <div className="chart-title">
        <h1 className="my-3">{title ?? ""}</h1>
        <Line options={options} data={data} />
      </div>
    );
  }
};

export default memo(LineChart);
