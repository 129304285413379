import React, { memo, useState } from "react";

import { Breadcrumb, Row, Col, Image, Form, Button } from "react-bootstrap";

import { useMount } from "hooks";

// import Services
import { getUserInfo, getPermission } from "Services";
// import Components
import WelcomeComponent from "components/Welcomes";

// import Images default
import ProfileDefault from "assets/images/profiles/profile_1.png";

const MaintainMembers = () => {
  const [userInfo, setUserInfo] = useState("");
  const [permissions, setPermissions] = useState([]);

  const handleCheckedPlants = (event) => {
    console.log(event?.target?.value);
    console.log(`Status: ${event?.target?.checked}`);
  };

  const fetchDataDefault = async () => {
    const user = await getUserInfo();
    const { data: plants } = await getPermission();
    setPermissions(plants);
    setUserInfo(user);
  };

  useMount(() => void fetchDataDefault());

  return (
    <section className="maintain-form">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item href="/manage-members">Manage Member</Breadcrumb.Item>
        <Breadcrumb.Item active>Add</Breadcrumb.Item>
      </Breadcrumb>
      <WelcomeComponent
        title={`Add Member`}
        user={`${userInfo?.displayName}`}
        desc={`, You can add member this here !`}
      />
      <Row>
        <Col xl={3} lg={3} md={4} sm={12} xs={12} className="mb-3">
          <Row>
            <Col xs={12}>
              <div className="image-profile-frame">
                <Image
                  src={ProfileDefault}
                  alt="profiles"
                  width={`100%`}
                  height={`auto`}
                />
              </div>
            </Col>
            <Col xs={12}>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>
                  Upload project with file type .jpeg .png .jpg
                </Form.Label>
                <Form.Control type="file" />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col xl={9} lg={9} md={8} sm={12} xs={12} className="mb-3">
          <Form>
            <Row>
              <Col xs={12}>
                <h1 className="header-profiles">SETTING ACCOUNTS</h1>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <Form.Group className="mb-3" controlId="form-username">
                  <Form.Label>Username</Form.Label>
                  <Form.Control type="text" placeholder="Enter Username" />
                  <Form.Text className="text-muted">
                    We'll never share your Username with anyone else.
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <Form.Group className="mb-3" controlId="form-password">
                  <Form.Label>Enter Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <h1 className="header-profiles">PROFILE ACCOUNTS</h1>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <Form.Group className="mb-3" controlId="form-firstname">
                  <Form.Label>Firstname</Form.Label>
                  <Form.Control type="text" placeholder="Enter Firstname" />
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <Form.Group className="mb-3" controlId="form-lastname">
                  <Form.Label>Lastname</Form.Label>
                  <Form.Control type="text" placeholder="Enter Lastname" />
                </Form.Group>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Form.Group className="mb-3" controlId="form-email">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                  />
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <Form.Group className="mb-3" controlId="form-position">
                  <Form.Label>Position</Form.Label>
                  <Form.Control type="text" placeholder="Enter Position" />
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <Form.Group className="mb-3" controlId="form-position">
                  <Form.Label>Telephone</Form.Label>
                  <Form.Control type="text" placeholder="Telephone Number" />
                </Form.Group>
              </Col>
              <Col xs={12} className="mb-3">
                <h1 className="header-profiles">ROLE PERMISSION</h1>
                <Form.Text className="text-muted">
                  Setting from customer have permission !
                </Form.Text>
              </Col>
              {permissions?.plantPermission?.map((pms, k) => (
                <Col
                  xl={3}
                  lg={3}
                  md={6}
                  sm={12}
                  xs={12}
                  key={k}
                  className="mb-3"
                >
                  <Form.Check
                    inline
                    label={`${pms?.plantName}`}
                    name={`projects-${pms?.id}`}
                    type="checkbox"
                    value={`${pms?.id}`}
                    onChange={handleCheckedPlants}
                  />
                </Col>
              ))}

              <Col xl={12} className="text-end">
                <Button variant="outline-success" type="submit">
                  Save Profile
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </section>
  );
};

export default memo(MaintainMembers);
