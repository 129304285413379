import { memo, useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import { useMount } from "hooks";

// Resful Services
import { getPowerMeterEnergyMonthlys } from "Services";

// Component Loading
import LoadingA from "components/Loadings/SecondLoad";

const BarCharts = ({ title }) => {
  const [display, setDisplay] = useState(false);
  const [energys, setEnergys] = useState({
    categories: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    series: [],
  });
  const options = {
    series: [
      {
        name: "kWh ",
        data: [...energys?.series],
      },
    ],
    chart: {
      type: "bar",
      height: "400",
      with: "auto",
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    colors: ["#44a1fb"],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: energys?.categories,
    },
  };

  const fetchDataDefault = async () => {
    setDisplay(false);
    const { data } = await getPowerMeterEnergyMonthlys();
    setEnergys({ ...energys, series: data?.series });
    setDisplay(true);
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {
    const findElement = document.querySelector(".bar-charts");
    if (findElement) {
      let chart = new ApexCharts(
        document.querySelector(".bar-charts"),
        options
      );
      chart.render();
    }
  }, [energys]);

  if (!display) {
    return (
      <>
        <LoadingA />
      </>
    );
  } else {
    return (
      <div className="mb-3 px-3">
        <div className="barchart-apex">
          <h1>{title ?? ""}</h1>
          <div className="bar-charts"></div>
        </div>
      </div>
    );
  }
};

export default memo(BarCharts);
