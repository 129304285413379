import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { Container } from "react-bootstrap";

// Pages
import Dashboards from "pages/Dashboards";
import ProjectPages from "pages/Projects";
import MemberPages from "pages/Members";
import MemberMaintain from "pages/Members/Maintains";

// import Project Monitorpages
import ProjectMonitors from "pages/Projects/Monitors";

const MainLayouts = () => {
  return (
    <div>
      <Header />
      <Container className="mt-3">
        <Routes>
          <Route exact path="/" element={<Dashboards />} />
          <Route
            exact
            path="/projects/:projectSlugs"
            element={<ProjectMonitors />}
          />
          <Route exact path="/projects" element={<ProjectPages />} />
          <Route exact path="/manage-members" element={<MemberPages />} />
          <Route
            exact
            path="/manage-members/add"
            element={<MemberMaintain />}
          />
          <Route exact path="/*" element={<NotFoundPages />} />
        </Routes>
      </Container>
      <Footer />
    </div>
  );
};

const NotFoundPages = () => {
  console.log("not found");
  return (
    <div>
      <h1>NOT FOUND PAGES !!</h1>
    </div>
  );
};

export default MainLayouts;
