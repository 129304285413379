import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCurrentRefreshToken,
  selectCurrentToken,
  selectCurrentUser,
} from "./authSlice";
import { getAccessToken, getRefreshToken } from "Services";

const RequireAuth = ({ children }) => {
  const token = useSelector(selectCurrentToken) ?? getAccessToken();
  const refreshToken =
    useSelector(selectCurrentRefreshToken) ?? getRefreshToken();
  const location = useLocation();

  return token && refreshToken ? (
    <Outlet> {children} </Outlet>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
