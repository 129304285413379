import { memo, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMount } from "hooks";

import Histories from "components/Histories";
import LineChart from "components/ApexCharts/LineChartEnergyTrend";
import AntVLineChart from "components/AntVCharts/LineChart";
import ApexBarChart from "components/ApexCharts/BarChartsEnergyTrends";

import { getDetailEnergyTrends, getBarMonthEnergyTrends } from "Services";

const DataPloters = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [selDate, setSelDate] = useState(new Date());
  const [enTrends, setEnTrends] = useState({
    labelArr: [],
    plotDisCArr: [],
    plotLoadArr: [],
  });
  const [bmEnTrends, setBmEnTrends] = useState({});

  const fetchDataDefault = async () => {
    await getDetailEnergyTrends(projectId, { date: selDate })
      .then((res) => {
        setEnTrends({ ...res?.data });
      })
      .catch((error) => {
        if (error) {
          navigate("/projects");
        }
      });

    await getBarMonthEnergyTrends(projectId)
      .then((bmet) => {
        setBmEnTrends(bmet?.data?.bars);
      })
      .catch((error) => {
        if (error) {
          navigate("/projects");
        }
      });
  };

  useEffect(() => {
    fetchDataDefault();
  }, [selDate]);

  useEffect(() => {
    setInterval(() => {
      setSelDate(new Date());
      fetchDataDefault();
    }, 60000 * 5);
  }, []);

  useMount(() => void fetchDataDefault());

  return (
    <section className="main-data px-3">
      <Row className="my-4">
        <Col xl={3} lg={3} md={6} sm={6} className={`mb-3`}>
          <h1 className="head-topic-xs">
            {<FontAwesomeIcon icon={faCalendarAlt} className="me-2" />}DATE
            FILTER{" "}
          </h1>
          <DatePicker
            onChange={(date) => setSelDate(date)}
            className="date-picker-customs"
            selected={selDate}
            showTimeSelect={false}
            timeIntervals={30}
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
          />
        </Col>
        <Col xl={12} lg={12} md={12} sm={12} className={`mb-3`}>
          <LineChart title={`ENERGY TREND`} datas={enTrends} />
        </Col>
        {/* <Col xl={12} lg={12} md={12} sm={12} className={`mb-3`}>
          <AntVLineChart />
        </Col> */}
        <Col xl={4} lg={4} md={12} sm={12} className={`mb-3`}>
          <ApexBarChart title={`CONSUMPTION`} datas={bmEnTrends} />
        </Col>
        <Col xl={8} lg={8} md={12} sm={12} className={`mb-3`}>
          <Histories title={`ENERGY DATA `} />
        </Col>
      </Row>
    </section>
  );
};

export default memo(DataPloters);
