import api from "./api";

const REPORTPOWERMETER_URL = "/reportpowermeters";
const POWERMETERURL = "/powermeters";

export const getPowerMeterTodayById = async (filter, Id) => {
  return api
    .get(`${REPORTPOWERMETER_URL}/days/${Id}`, {
      params: filter,
    })
    .then((res) => res.data);
};

export const getPowerMeterUseTodayById = async (filter, Id) => {
  return api
    .get(`${REPORTPOWERMETER_URL}/dayused/${Id}`, {
      params: filter,
    })
    .then((res) => res.data);
};

export const getPowerMeterUseMonthlyById = async (filter, Id) => {
  return api
    .get(`${REPORTPOWERMETER_URL}/monthused/${Id}`, {
      params: filter,
    })
    .then((res) => res.data);
};

export const getPowerMeterEnergyDailys = async (filter) => {
  return api
    .get(`${REPORTPOWERMETER_URL}/daily`, {
      params: filter,
    })
    .then((res) => res.data);
};

export const getPowerMeterEnergyMonthlys = async (filter) => {
  return api
    .get(`${REPORTPOWERMETER_URL}/monthly`, {
      params: filter,
    })
    .then((res) => res.data);
};

export const getPowerMeterHistories = async (filter) => {
  return api
    .get(`${REPORTPOWERMETER_URL}/histories`, {
      params: filter,
    })
    .then((res) => res.data);
};

export const getCardCalculators = async (Id) => {
  return api.get(`${REPORTPOWERMETER_URL}/cards/${Id}`).then((res) => res.data);
};

export const getPowerMeterMutiResult = async (filter, Id) => {
  return api
    .get(`${REPORTPOWERMETER_URL}/results/${Id}`, {
      params: filter,
    })
    .then((res) => res.data);
};
