import { memo, useState, useEffect } from "react";
import { Table, Pagination, Col, Row } from "react-bootstrap";
import { useMount } from "hooks";
import moment from "moment";

import { getPowerMeterHistories } from "Services";

// Component Loading
import LoadingA from "components/Loadings/SecondLoad";

const initPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  lastPage: 0,
};

const Histories = ({ title }) => {
  const [display, setDisplay] = useState(false);
  const [dataHistories, setDataHistories] = useState({
    filter: {},
    data: [],
    active: 1,
    number: 1,
    pagination: {
      ...initPagination,
    },
  });

  const handleChangePage = (number) => {
    if (number <= 3) {
      setDataHistories({
        ...dataHistories,
        number: 1,
        active: number,
      });
    } else if (number === dataHistories?.pagination?.lastPage - 1) {
      setDataHistories({
        ...dataHistories,
        number: number - 3,
        active: number,
      });
    } else if (number === dataHistories?.pagination?.lastPage) {
      setDataHistories({
        ...dataHistories,
        number: number - 4,
        active: number,
      });
    } else {
      setDataHistories({
        ...dataHistories,
        number: number - 2,
        active: number,
      });
    }
  };

  const handleChangeNext = () => {
    if (dataHistories?.active >= dataHistories?.pagination?.lastPage) {
      return false;
    }

    handleChangePage(dataHistories?.active + 1);
  };

  const handleChangePrev = () => {
    if (dataHistories?.active > 0) {
      if (dataHistories?.active === 1) {
        handleChangePage(dataHistories?.active);
      } else {
        handleChangePage(dataHistories?.active - 1);
      }
    }
  };

  const handleLastPage = () => {
    handleChangePage(dataHistories?.pagination?.lastPage);
  };

  const items = [];

  for (
    let number = dataHistories?.number;
    number <= dataHistories?.number + 4;
    number++
  ) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === dataHistories?.active}
        onClick={() => handleChangePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const fetchDataDefault = async () => {
    setDisplay(false);

    const filter = {
      page: dataHistories?.active,
    };

    const { data } = await getPowerMeterHistories(filter);

    const current = data?.paginate?.current_page || 1;
    const pageSize = data?.paginate?.per_page || 10;
    const total = data?.paginate?.total || 0;
    const lastPage = data?.paginate?.last_page || 0;

    setDataHistories({
      ...dataHistories,
      data: data?.histories?.data,
      pagination: {
        ...dataHistories.paginate,
        current,
        pageSize,
        total,
        lastPage,
      },
    });

    setDisplay(true);
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {
    fetchDataDefault();
  }, [dataHistories?.active]);

  if (!display) {
    return (
      <>
        <LoadingA />
      </>
    );
  } else {
    return (
      <div className="histories">
        <h1>{title ?? `DATA HISTORIES`}</h1>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th>Device Name</th>
              <th className="text-end">Power Current (kW)</th>
              <th className="text-end">Power Factor</th>
              <th className="text-end">Total Power (kWh)</th>
              <th className="text-end">Time Stamp</th>
            </tr>
          </thead>
          <tbody>
            {dataHistories?.data?.map((res, key) => (
              <tr key={key}>
                <td className="text-center">{`${
                  dataHistories?.active <= 1
                    ? (key += 1)
                    : (key += 1) + dataHistories?.active * 10
                }`}</td>
                <td>{res?.deviceName}</td>
                <td className="text-end">{res?.pKw}</td>
                <td className="text-end">{res?.pf}</td>
                <td className="text-end">{res?.tKwh}</td>
                <td className="text-end">
                  {moment(res?.timer).format("DD/MM/yyyy HH:mm:ss")}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row>
          <Col lg={12}>
            <Pagination size="sm" className="justify-content-end">
              <Pagination.First onClick={() => handleChangePage(1)} />
              <Pagination.Prev onClick={() => handleChangePrev()} />
              {items}
              <Pagination.Next onClick={() => handleChangeNext()} />
              <Pagination.Last onClick={() => handleLastPage()} />
            </Pagination>
          </Col>
        </Row>
      </div>
    );
  }
};

export default memo(Histories);
