import { memo, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";

const PinMap = ({ text }) => (
  <div className="w-100">
    <b>{text}</b>
  </div>
);

const GoogleMaps = ({ lat, lon, projectName }) => {
  const defaultProps = {
    center: {
      lat: lat ?? 13.8350117,
      lng: lon ?? 100.6599541,
    },
    zoom: 20,
  };

  useEffect(() => {}, []);

  return (
    // Important! Always set the container height explicitly
    <>
      <div className="mb-3" style={{ height: 310, width: "auto" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLEMAP_KEY}` }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <PinMap lat={lat} lng={lon} text={`${projectName}`} />
        </GoogleMapReact>
      </div>
    </>
  );
};

export default memo(GoogleMaps);
