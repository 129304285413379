import { memo, useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import { useMount } from "hooks";

// Resful Services
import { getPowerMeterUseTodayById } from "Services";

// Component Loading
import LoadingA from "components/Loadings/SecondLoad";

function RadialBar({ title, id }) {
  const [used, setUsed] = useState(0);
  const [display, setDisplay] = useState(false);
  const options = {
    chart: {
      height: 280,
      type: "radialBar",
    },
    series: [used],
    colors: ["#20E647"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "70%",
          background: "#293450",
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15,
          },
        },
        dataLabels: {
          name: {
            offsetY: -10,
            color: "#fff",
            fontSize: "13px",
          },
          value: {
            color: "#fff",
            fontSize: "20px",
            show: true,
            formatter: function (val) {
              return val + " kWh";
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: ["#87D4F9"],
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["POWER USED"],
  };

  const fetchDataDefault = async () => {
    setDisplay(false);
    const filter = {};
    const { data } = await getPowerMeterUseTodayById(filter, id);
    setUsed(data?.used);
    setDisplay(true);
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {
    const elementId = `#pwToday-${id}`;
    const findElement = document.querySelector(elementId);

    if (findElement) {
      const chart = new ApexCharts(document.querySelector(elementId), options);
      chart.render();
    }
  }, [used]);
  if (!display) {
    return (
      <>
        <LoadingA />
      </>
    );
  } else {
    return (
      <>
        <div className="chart-title">
          <h1>{title ?? "ENERGY POWER"}</h1>
          <div id={`pwToday-${id}`} />
        </div>
      </>
    );
  }
}

export default memo(RadialBar);
