import { memo } from "react";
import LoadingSVG from "assets/images/loadings/SpinB.svg";

const Loading = () => {
  return (
    <>
      <div className="text-center loading-chart mb-3">
        <img className="mb-3" src={LoadingSVG} alt="loading" width={60} />
        <h1 className="head-topic-xs"> L O A D I N G . . . </h1>
      </div>
    </>
  );
};

export default memo(Loading);
