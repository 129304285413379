import api from "./api";

const DASHBOARDURL = "/dashboard";

// get member managements
export const getDashboards = async (filter) => {
  return api
    .get(DASHBOARDURL, {
      params: filter,
    })
    .then((res) => res.data);
};
