import { memo, useState, useEffect } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useMount } from "hooks";

// import Services
import { getUserInfo } from "Services";

// import Components
import WelcomeComponent from "components/Welcomes";
import MemberCards from "components/Members/CardProfile";

const Members = () => {
  const [userInfo, setUserInfo] = useState("");

  const fetchDataDefault = async () => {
    const user = await getUserInfo();
    setUserInfo(user);
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {}, []);

  return (
    <section className="main-project">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item active>Manage Member</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xl={10} lg={10} md={10} sm={10} xs={10}>
          <WelcomeComponent
            title={`Manage Member`}
            user={`${userInfo?.displayName}`}
            desc={`, You can manage member this here !`}
          />
        </Col>
        <Col xl={2} lg={2} md={2} sm={2} xs={2} className="text-end">
          <Button variant="outline-info" href="/manage-members/add">
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
        <Row>
          <Col xl={4} lg={4} md={6} sm={12} xs={12} className={`mb-3`}>
            <Form>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                  id="search"
                  placeholder="Enter name for search..."
                />
              </InputGroup>
            </Form>
          </Col>
          <Col xs="auto" className={`mb-3`}>
            <Button variant="outline-secondary" type="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Row>
      <Row>
        <Col xl={3} lg={3} md={4} sm={6} xs={12}>
          <MemberCards />
        </Col>
      </Row>
    </section>
  );
};

export default memo(Members);
