import { memo } from "react";

const Welcomes = ({ title, desc, user }) => {
  return (
    <div className="welcome">
      <h1>{title ?? ``}</h1>
      <p>
        {`Hello `}
        <b className="text-primary">{user ?? "Guest"}</b>
        {desc ?? ``}
      </p>
    </div>
  );
};

export default memo(Welcomes);
