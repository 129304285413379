import { memo, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Breadcrumb, Image } from "react-bootstrap";
import { useMount } from "hooks";
import { base64 } from "utils";

// import MQTT
import { useSubscription } from "mqtt-react-hooks";

// import Service
import { getDetailProject, getCardCalculators } from "Services";

//custom Components
import Welcome from "components/Welcomes/monitor";
import ProjectCards from "components/ProjectCards";
import GoogleMap from "components/GoogleMaps/monitormap";
import TableMonitorings from "components/TableMonitorings";
import Analytics from "components/Analytics";
import BarCharts from "components/ApexCharts/BarCharts";

// import Image Icon
import HomeIcon from "assets/images/icons/home.png";
import ThunderIcon from "assets/images/icons/bolt.png";
import Wallet from "assets/images/icons/wallet.png";
import Co2 from "assets/images/icons/co2-cloud.png";

const EnergyComponent = () => {
  const { projectSlugs } = useParams();
  const [brokers, setBrokers] = useState([]);
  const [cards, setCards] = useState({
    daily: "0 kWh",
    monthly: "0 kWh",
    pricing: "0 Baht",
    carbon: "0 kgs",
  });
  const [project, setProject] = useState({});
  const [devices, setDevices] = useState([]);
  const [dataPowers, setDataPowers] = useState([]);

  const fetchDataDefault = async () => {
    const msgBroker = [];

    const { data: results } = await getDetailProject(
      base64.b64ToUtf8(projectSlugs)
    );

    const { data: cards } = await getCardCalculators(
      base64.b64ToUtf8(projectSlugs)
    );

    setCards(cards);

    setProject(results?.project);
    setDevices(results?.devices);
    results?.devices?.map((dv) => {
      return msgBroker.push(dv?.topicBm);
    });

    setBrokers(msgBroker);
  };

  const { message } = useSubscription(brokers);

  const handleBrokerProtocals = (message) => {
    if (typeof message?.message === "string") {
      const convertMessageToJson = JSON.parse(message?.message);
      setDataPowers({
        ...dataPowers,
        topic: message?.topic,
        desc: { ...convertMessageToJson },
      });
    }
  };

  useMount(() => void fetchDataDefault());

  // Update Brokers
  useEffect(() => {
    handleBrokerProtocals(message);
  }, [message]);

  useEffect(() => {}, [project]);

  return (
    <section className="section-monitor px-3">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item href="/projects">Projects</Breadcrumb.Item>
        <Breadcrumb.Item active>{project?.plantName}</Breadcrumb.Item>
      </Breadcrumb>
      <Row className="mb-3">
        <Col xl={3} lg={4}>
          <Welcome
            title={`${project?.plantName}`}
            desc={`${project?.plantDescription}`}
          />
        </Col>
        <Col xl={9} lg={8}>
          <Row>
            <Col xl={3} lg={6} md={6} sm={12} xs={12}>
              <ProjectCards
                colors={`orange`}
                title={`TODAY CONSUMPTION`}
                icon={ThunderIcon}
                value={cards?.daily}
              />
            </Col>
            <Col xl={3} lg={6} md={6} sm={12} xs={12}>
              <ProjectCards
                colors={`pink`}
                title={`TOTAL CONSUMPTION`}
                icon={HomeIcon}
                value={cards?.monthly}
              />
            </Col>
            <Col xl={3} lg={6} md={6} sm={12} xs={12}>
              <ProjectCards
                colors={`yellow`}
                title={`MONEY BILLING`}
                icon={Wallet}
                value={cards?.pricing}
              />
            </Col>
            <Col xl={3} lg={6} md={6} sm={12} xs={12}>
              <ProjectCards
                colors={`green`}
                title={`CARBON REDUCE`}
                icon={Co2}
                value={cards?.carbon}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={3} lg={4}>
          <Row>
            <Col xs={12}>
              <Image
                src={project?.plantImagePath}
                className="w-100 mb-3"
              ></Image>
            </Col>
            <Col xs={12}>
              <GoogleMap
                lat={project?.latitude}
                lon={project?.longitude}
                projectName={`${project?.plantName}`}
              />
            </Col>
            <Col xs={12} className="mt-3">
              <BarCharts title={`MONTHLY ENERGY `} />
            </Col>
          </Row>
        </Col>
        <Col xl={6} lg={8}>
          <Analytics initalData={devices} />
        </Col>
        <Col xl={3} lg={12} sm={12} className="mb-3">
          {devices?.map((devs, devk) => {
            return (
              <TableMonitorings
                title={devs?.deviceConnectName}
                initalData={
                  devs?.topicBm === dataPowers?.topic && dataPowers?.desc
                }
                key={devk}
              />
            );
          })}
        </Col>
      </Row>
    </section>
  );
};

export default memo(EnergyComponent);
