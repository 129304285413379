import { memo } from "react";
import { Carousel } from "react-bootstrap";

const Banners = ({ initialData }) => {
  console.log(initialData);
  return (
    <div className="banners">
      <h1 className="xxx">BANNERS</h1>
      <p>
        The vision of our company is to be the Ultimate Energy Solutions
        Provider. To achieve our vision, we put our resources into two core
        businesses; Clean Energy Innovation and Clean Energy Generation.
      </p>
      <Carousel>
        {initialData.map((bn, k) => (
          <Carousel.Item key={k}>
            <img
              className="d-block w-100"
              src={bn?.imagePath}
              style={{ width: "100%" }}
            />
            <Carousel.Caption>
              <h3>{bn?.topic}</h3>
              <p dangerouslySetInnerHTML={{ __html: bn?.description }}></p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default memo(Banners);
