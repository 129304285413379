import { memo } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Services
import { getUserInfo, logout } from "Services";

// import Logos
import Logo from "assets/images/logos/logo.png";
import examProfile from "assets/images/icons/robot.png";
import LogoutIcon from "assets/images/icons/log-out.png";
import MemberIcon from "assets/images/icons/member.png";

const Header = () => {
  const userInfo = getUserInfo();

  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <Link to="/">
              <img src={Logo} style={{ width: 120 }} alt="logo-bands" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/">Dashboard</Nav.Link>
              <Nav.Link href="/projects">Projects</Nav.Link>
              <NavDropdown title="Product And Service" id="basic-nav-dropdown">
                <NavDropdown.Item href="/members" className="dropdown-customs">
                  Members
                </NavDropdown.Item>
                <NavDropdown.Item href="/monitors" className="dropdown-customs">
                  Projects
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title={
                  <>
                    <label className="profile-name ms-3">
                      {userInfo?.displayName ?? "unknow"}
                    </label>
                    <img
                      src={examProfile}
                      className="profile-icon"
                      alt="profiles"
                    />
                  </>
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/profiles" className="dropdown-customs">
                  <div>
                    <img
                      src={examProfile}
                      className="profile-mini"
                      alt="profiles"
                    />
                    <label className="dropdown-profile">My Profile</label>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/manage-members"
                  className="dropdown-customs"
                >
                  <div>
                    <img
                      src={MemberIcon}
                      className="profile-mini"
                      alt="profiles"
                    />
                    <label className="dropdown-profile">Manage Members</label>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => logout()}
                  className="dropdown-customs"
                >
                  <div>
                    <img
                      src={LogoutIcon}
                      className="profile-mini"
                      alt="profiles"
                    />
                    <label className="dropdown-profile">Sign-out</label>
                  </div>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default memo(Header);
