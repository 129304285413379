import api from "./api";

const PROJECTSURL = "/projects";

export const getProjects = async (filter) => {
  return api
    .get(PROJECTSURL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const getDetailProject = async (plantId, filter) => {
  return api
    .get(`${PROJECTSURL}/${plantId}`, {
      params: filter,
    })
    .then((res) => res.data);
};

export const getPermission = async () => {
  return api.get(`${PROJECTSURL}/permissions`).then((res) => res.data);
};
