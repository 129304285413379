import { memo } from "react";
import { NavLink } from "react-router-dom";
import { Card, Button } from "react-bootstrap";

const Cards = ({ title, description, images, path }) => {
  return (
    <Card className="mb-3">
      <Card.Img variant="top" src={images} />
      <Card.Body>
        <Card.Title>
          <NavLink to={path}> {title ?? ""}</NavLink>
        </Card.Title>
        <Card.Text
          dangerouslySetInnerHTML={{ __html: description }}
        ></Card.Text>
        <div className="text-end mt-3">
          <Button variant="outline-info" size={`sm`} href={path}>
            Preview
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default memo(Cards);
