import { memo, useState, useEffect } from "react";
import ApexCharts from "apexcharts";
import LoadingA from "components/Loadings/SecondLoad";

const LineChartEnergyTrend = ({ title, datas }) => {
  const [display, setDisplay] = useState(false);
  const options = {
    series: [
      {
        name: "Discharge",
        data: [...datas?.plotDisCArr],
      },
      {
        name: "Load Consumption",
        data: [...datas?.plotLoadArr],
      },
    ],
    chart: {
      height: 400,
      type: "area",
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#ff9210", "#e53998"],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      categories: [...datas?.labelArr],
      tickAmount: 24,
    },
    tooltip: {
      x: {
        format: "HH:mm",
      },
    },
  };

  useEffect(() => {
    setDisplay(false);
    setTimeout(() => {
      setDisplay(true);
    }, 2000);
  }, [datas]);

  useEffect(() => {
    let chart = new ApexCharts(
      document.querySelector(".chart-energyTrend"),
      options
    );
    chart.render();
  }, [display]);

  if (!display) {
    return (
      <>
        <LoadingA />
      </>
    );
  }

  return (
    <>
      <div className="chart-title">
        <h1>{title ?? ""}</h1>
        <div className="chart-energyTrend" />
      </div>
    </>
  );
};

export default memo(LineChartEnergyTrend);
