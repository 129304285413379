import api from "./api";

const ENERGYTRENDSURL = "/energy-trends";

export const getDetailEnergyTrends = async (plantId, filter) => {
  return api
    .get(`${ENERGYTRENDSURL}/${plantId}`, {
      params: filter,
    })
    .then((res) => res.data);
};

export const getBarMonthEnergyTrends = async (plantId, filter) => {
  return api
    .get(`${ENERGYTRENDSURL}/bars/${plantId}`, {
      params: filter,
    })
    .then((res) => res.data);
};
