import { memo, useState, useEffect } from "react";
import { Breadcrumb, Row, Col } from "react-bootstrap";

// import Components
import Welcomes from "components/Welcomes";
import Anouncements from "components/Anouncements";
import HistoriesTables from "components/Histories";
import LineChart from "components/ApexCharts/PowerMeterCharts/MultilineCharts";
import BarCharts from "components/ApexCharts/BarCharts";
import Weathers from "components/Weathers";
import ProjectCards from "components/ProjectCards";
import Banners from "components/Banners";

// Image Icon
import ProjectIcon from "assets/images/icons/projects.png";
import ThunderIcon from "assets/images/icons/bolt.png";
import WalletIcon from "assets/images/icons/wallet.png";
import UserIcon from "assets/images/icons/user.png";

import { useMount } from "hooks";

import { getUserInfo, getDashboards } from "Services";

const Dashboards = () => {
  const [userInfo, setUserInfo] = useState("");

  const [dashboard, setDashboard] = useState({
    plantCount: 0,
    anouncement: {},
    banners: [],
  });

  const fetchDataDefault = async () => {
    const user = await getUserInfo();
    const { data } = await getDashboards();

    setUserInfo(user);
    setDashboard({
      ...data,
    });
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {}, []);

  return (
    <section className="main-project">
      <Breadcrumb>
        <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col xl={12}>
          <Welcomes
            title={`Welcome to Dashboard`}
            user={`${userInfo?.displayName}`}
            desc={`, welcome to monitoring energy dashboard!`}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={5}>
          <Anouncements initialData={dashboard?.anouncement} />
        </Col>
        <Col xl={2}>
          <ProjectCards
            colors={`default`}
            title={`YOUR PROJECTS`}
            icon={ProjectIcon}
            value={dashboard?.plantCount}
          />
          <ProjectCards
            colors={`blue`}
            title={`ENERGY SUMMARY`}
            icon={ThunderIcon}
          />
        </Col>
        <Col xl={2}>
          <ProjectCards
            colors={`yellow`}
            title={`BILLINGS`}
            icon={WalletIcon}
          />
          <ProjectCards colors={`pink`} title={`MEMBERS`} icon={UserIcon} />
        </Col>
        <Col xl={3}>
          <Weathers />
        </Col>
      </Row>
      <Row>
        <Col xl={5}>
          <LineChart title={`DAILY ENERGY`} id={2} />
        </Col>
        <Col xl={3}>
          <BarCharts title={`MONTHLY ENERGY `} />
        </Col>
        <Col xl={4}>
          <Banners initialData={dashboard?.banners} />
        </Col>
      </Row>
      <Row>
        <Col xl={8}>
          <HistoriesTables />
        </Col>
        <Col xl={4}></Col>
      </Row>
    </section>
  );
};

export default memo(Dashboards);
