import { memo } from "react";
import { Row, Col, Image } from "react-bootstrap";

const ProjectCards = ({ colors, title, icon, value }) => {
  return (
    <div className={`card-list ${colors}`}>
      <h1>{title ?? ""}</h1>
      <hr></hr>
      <Row className="my-3">
        <Col sm={4} xs={4}>
          <Image src={`${icon}`} width={52} className={`hover-image`} />
        </Col>
        <Col sm={8} xs={8}>
          <p className="text-end number-card">{value ?? 0}</p>
        </Col>
      </Row>
    </div>
  );
};

export default memo(ProjectCards);
