import { memo } from "react";
import { Connector } from "mqtt-react-hooks";
import EnergyComponent from "./EnergyComponent";
import DataEnergys from "./data";

const BrokerMqtt = () => {
  const optionSettings = {
    username: process.env.REACT_APP_MQTT_USERNAME,
    password: process.env.REACT_APP_MQTT_PASSWORD,
  };

  return (
    <div>
      <Connector
        brokerUrl={`${process.env.REACT_APP_MQTT}`}
        options={optionSettings}
      >
        <EnergyComponent />
      </Connector>
      {/* <DataEnergys /> */}
    </div>
  );
};

export default memo(BrokerMqtt);
