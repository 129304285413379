import { memo } from "react";

const Monitors = ({ title, desc }) => {
  return (
    <section className="main">
      <div className="welcome">
        <h1>{title ?? ``}</h1>
        <p dangerouslySetInnerHTML={{ __html: desc }}></p>
      </div>
    </section>
  );
};

export default memo(Monitors);
