import { memo } from "react";
import { Row, Col, Tab, Tabs } from "react-bootstrap";

import PmtLineCharts from "../ApexCharts/PowerMeterCharts/LineCharts";
import RedialBarToday from "../ApexCharts/PowerMeterCharts/RadialBar";
import RadialBarMonth from "../ApexCharts/PowerMeterCharts/RadialBarMonth";
import MultilineCharts from "../ApexCharts/PowerMeterCharts/MultilineCharts";

function Analytics({ initalData }) {
  return (
    <Tabs
      defaultActiveKey="0"
      id="uncontrolled-tab-powermeter"
      className="mb-3"
    >
      {initalData?.map((dev, key) => {
        return (
          <Tab eventKey={key} key={key} title={`${dev?.deviceConnectName}`}>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} className="px-3">
                <RedialBarToday
                  title={`ENERGY TODAY`}
                  id={dev?.deviceAndConnectId}
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="px-3">
                <RadialBarMonth
                  title={`ENERGY MONTHLY`}
                  id={dev?.deviceAndConnectId}
                  value={65}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} className="px-3">
                <PmtLineCharts
                  id={dev?.deviceAndConnectId}
                  title={`ENERGY TODAY`}
                />
                {/* <LineCharts title={`DAILY ENERGY`} id={dev?.id} /> */}
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} className="px-3">
                <MultilineCharts
                  id={dev?.deviceAndConnectId}
                  title={`POWERMETER HISTORIES`}
                />
                {/* <LineCharts title={`DAILY ENERGY`} id={dev?.id} /> */}
              </Col>
            </Row>
          </Tab>
        );
      })}
    </Tabs>
  );
}

export default memo(Analytics);
