import { memo } from "react";
import { Row, Col } from "react-bootstrap";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="footer-section">
      <div className="text-center text-footer">
        {dayjs().format("YYYY")} © Develop by Enserv Power
      </div>
      <Row className="text-center my-3 mx-0">
        <Col sm={12} lg={4} md={6}>
          <p className="head-footer">About me</p>
          <div>
            <Link to="/#">GET TO KNOW US</Link>
          </div>
          <div>
            <Link to="/#">PROMOS</Link>
          </div>
          <div>
            <Link to="/#">BECOME A RETAILER</Link>
          </div>
          <div>
            <Link to="/#">JOB OPENINGS</Link>
          </div>
          <div>
            <Link to="/#">EVENTS</Link>
          </div>
        </Col>
        <Col sm={12} lg={4} md={6}>
          <p className="head-footer">The Gift Selection</p>
          <div>
            <Link to="/#">ANGEL FIGURINES</Link>
          </div>
          <div>
            <Link to="/#">HOME DECOR</Link>
          </div>
          <div>
            <Link to="/#">MUGS</Link>
          </div>
          <div>
            <Link to="/#">PET LOVER</Link>
          </div>
          <div>
            <Link to="/#">HANDBAGS & JEWELRY</Link>
          </div>
        </Col>
        <Col sm={12} lg={4} md={6}>
          <p className="head-footer">Please Help Me</p>
          <div>
            <Link to="/#">CONTACT</Link>
          </div>
          <div>
            <Link to="/#">FAQ</Link>
          </div>
          <div>
            <Link to="/#">STORE LOCATOR</Link>
          </div>
          <div>
            <Link to="/#">NEW USERS</Link>
          </div>
          <div>
            <Link to="/#">ORDER STATUS</Link>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default memo(Footer);
