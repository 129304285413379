import React, { memo } from "react";
import { Table, Card } from "react-bootstrap";

const TableMonitorings = ({ title, initalData }) => {
  return (
    <section className="main">
      <h1 className="head-topic-sm">{title ?? ``}</h1>
      <div className="histories mb-4">
        <Table striped>
          <thead>
            <tr>
              <th>Status</th>
              <th>Data Name</th>
              <th className="text-center">Value</th>
              <th className="text-end">Unit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="ledContainer text-center">
                  <i
                    className={`recordLed small ${
                      initalData?.ins_kw ? `online` : `offline`
                    } live mx-1`}
                  ></i>
                </div>
              </td>
              <td>Power</td>
              <td className="text-center">
                {parseFloat(initalData?.ins_kw ?? 0).toFixed(2)}
              </td>
              <td className="text-end">
                <b>kW</b>
              </td>
            </tr>
            <tr>
              <td>
                <div className="ledContainer text-center">
                  <i
                    className={`recordLed small ${
                      initalData?.kwh_t ? `online` : `offline`
                    } live mx-1`}
                  ></i>
                </div>
              </td>
              <td>Power Total</td>
              <td className="text-center">
                {parseFloat(initalData?.kwh_t ?? 0).toFixed(2)}
              </td>
              <td className="text-end">
                <b>kWh</b>
              </td>
            </tr>
            <tr>
              <td>
                <div className="ledContainer text-center">
                  <i
                    className={`recordLed small ${
                      initalData?.ins_kvar ? `online` : `offline`
                    } live mx-1`}
                  ></i>
                </div>
              </td>
              <td>Reactive Power</td>
              <td className="text-center">
                {parseFloat(initalData?.ins_kvar ?? 0).toFixed(2)}
              </td>
              <td className="text-end">
                <b>kVAR</b>
              </td>
            </tr>
            <tr>
              <td>
                <div className="ledContainer text-center">
                  <i
                    className={`recordLed small ${
                      initalData?.kvarh_t ? `online` : `offline`
                    } live mx-1`}
                  ></i>
                </div>
              </td>
              <td>Reactive Power Total</td>
              <td className="text-center">
                {parseFloat(initalData?.kvarh_t ?? 0).toFixed(2)}
              </td>
              <td className="text-end">
                <b>kVARh</b>
              </td>
            </tr>
            <tr>
              <td>
                <div className="ledContainer text-center">
                  <i
                    className={`recordLed small ${
                      initalData?.pow_factor ? `online` : `offline`
                    } live mx-1`}
                  ></i>
                </div>
              </td>
              <td>Power Factor</td>
              <td className="text-center">
                {parseFloat(initalData?.pow_factor ?? 0).toFixed(2)}
              </td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default memo(TableMonitorings);
