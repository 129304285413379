import { memo, useState } from "react";
import { Breadcrumb, Row, Col } from "react-bootstrap";
import { useMount } from "hooks";
import { getProjects } from "Services";
import { base64 } from "utils";

import GoogleMaps from "components/GoogleMaps";
import CardLinks from "components/Cards";

import StationImg from "assets/images/banners/100781757.jpeg";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const fetchDataDefault = async () => {
    const { data } = await getProjects();

    setProjects(data?.projects);
  };

  useMount(() => void fetchDataDefault());

  return (
    <section className="main-project">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item active>Projects</Breadcrumb.Item>
      </Breadcrumb>
      <div>
        <GoogleMaps />
      </div>
      <Row className="mb-3">
        {projects?.map((p, k) => (
          <Col xl={3} lg={4} md={4} sm={6} key={k}>
            <CardLinks
              path={`/projects/${base64.utf8ToB64(p.slugs)}`}
              images={p?.plantImagePath}
              title={`${p?.plantName}`}
              description={`${p?.plantDescription}`}
            />
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default memo(Projects);
