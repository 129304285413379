import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Form,
  Button,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setCredentials } from "./authSlice";
import { useLoginMutation } from "./authApiSlice";
import { setToken, setRefreshToken, getAuthInfo } from "Services";

import "styles/customs/login.scss";

// import Images
import EnservIcon from "assets/images/logos/logo.png";

const Login = () => {
  const userRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  useEffect(() => {}, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await login({ user, pwd }).unwrap();
      dispatch(setCredentials({ ...userData, user }));
      setToken(userData?.token);
      setRefreshToken(userData?.refreshToken);
      await getAuthInfo();
      setUser("");
      setPwd("");
      navigate("/");
    } catch (error) {
      if (error?.status === 400) {
        setErrMsg("Username or Password is wrong !");
      } else if (error?.status === 401) {
        setErrMsg("Unauthorized !");
      } else if (!error) {
        setErrMsg("No Server Response");
      } else {
        setErrMsg("Authentication is Failed !");
      }

      // console.log("hello world");

      errRef.current.focus();
    }
  };

  const handleUserInput = (e) => setUser(e.target.value);
  const handlePasswordInput = (e) => setPwd(e.target.value);

  const content = isLoading ? (
    <h1> Loading...</h1>
  ) : (
    <section className="background-login">
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <div className="border border-primary"></div>
            <Card className="shadow card-customs">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <Image src={EnservIcon} width={256} className="mb-3" />
                  <h1 className="topic-login">Platform monitor electical</h1>
                  <p className="mb-3">Please enter your login and password!</p>
                  <p
                    ref={errRef}
                    className={errMsg ? "text-danger" : "offscreen"}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                  <div className="mb-3">
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          <b>Username</b>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Username"
                          autoComplete="off"
                          onChange={handleUserInput}
                          value={user}
                          ref={userRef}
                          required
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>
                          <b>Password</b>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          onChange={handlePasswordInput}
                          value={pwd}
                          placeholder="Password"
                          autoComplete="off"
                          required
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <p className="small">
                          <a href="#!">Forgot password?</a>
                        </p>
                      </Form.Group>
                      <div className="d-grid">
                        <Button
                          variant="primary"
                          className="btn-login"
                          type="submit"
                        >
                          Login
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        Don't have an account?{" "}
                        <a href="{''}" className="fw-bold">
                          Sign Up
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            {/* <div className="text-center mt-3">
              <lable>© Develop by Enserv Power Co., Ltd.</lable>
            </div> */}
          </Col>
        </Row>
        <div className="container-fluid h-custom"></div>
      </Container>
    </section>
  );

  return content;
};

export default Login;
