import { memo, useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEdit,
  faTable,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

// import Component
import Histories from "components/Histories";

import HeaderImages from "assets/images/banners/header-member.jpeg";

const CardProfile = () => {
  //handle Canvas modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="mb-3">
      <div className="card profile-card-3 mb-3">
        <div className="background-block">
          <img src={HeaderImages} alt="header-member" className="background" />
        </div>
        <div className="profile-thumb-block">
          <img
            src="https://randomuser.me/api/portraits/men/28.jpg"
            alt="profile-member"
            className="profile"
          />
        </div>
        <div className="card-content">
          <h2>
            <Link className="profile-name-member" to="#">
              Nuy Clover
            </Link>
            <small className="text-orange"> Full Stack Developer </small>
          </h2>
          <div className="icon-block">
            <Link to="#">
              <i>
                <FontAwesomeIcon icon={faEye} />
              </i>
            </Link>
            <Link to="#">
              <i>
                <FontAwesomeIcon icon={faTable} onClick={handleShow} />
              </i>
            </Link>
            <Link to="#">
              <i>
                <FontAwesomeIcon icon={faEdit} />
              </i>
            </Link>
            <Link to="#">
              <i>
                <FontAwesomeIcon icon={faTrash} />
              </i>
            </Link>
          </div>
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Histories Logs</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Histories />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default memo(CardProfile);
